import Homescreen from './Pages/Homescreen';
import AutoplayVideo from './Components/Autoplayvideo';
import ImageSlideshow from './Components/ImageSlideshow';
import Footercomponent from './Components/Footercomponent';
import './App.css';

function App() {
  return (
    <div className="App">
     <Homescreen/>
    </div>
  );
}

export default App;
